import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import type { ListTeamRes } from "@/types/teams";
import { Users } from "lucide-react";

interface TeamsListProps {
	suffixPath?: string;
	teams: ListTeamRes;
}

export const TeamsList = ({ suffixPath, teams }: TeamsListProps) => {
	const onTeamChange = (teamId: string) => {
		const team = teams.find((team) => team.id === teamId);
		if (team) {
			const prefixPath = team.personal ? "/" : `/teams/${team.id}/`;
			window.location.href = prefixPath + suffixPath;
		}
	};

	return (
		<Select onValueChange={onTeamChange}>
			<SelectTrigger className="w-[180px]">
				<SelectValue placeholder="Change Account" />
			</SelectTrigger>
			<SelectContent>
				<SelectGroup>
					{teams?.map((team) => (
						<SelectItem
							className="cursor-pointer"
							key={team.id}
							value={team.id}
						>
							<Users className="inline-block mr-2" size={18} />
							{team.name}
						</SelectItem>
					))}
				</SelectGroup>
			</SelectContent>
		</Select>
	);
};
