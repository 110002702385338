import type { GetDocRes } from "@/types/docs";

export const stripHtml = (html: string) => {
	const tmpEl = document.createElement("div");
	tmpEl.innerHTML = html;
	return tmpEl.textContent ?? tmpEl.innerText ?? "";
};

export const copyDoc = (doc: GetDocRes, markdown?: boolean) => {
	const content = `${doc.title}\n\n${doc.sections
		.map(
			(section) =>
				`${section.title}\n${markdown ? section.text : stripHtml(section.html)}`,
		)
		.join("\n\n")}`;
	navigator.clipboard.writeText(content);
};

export const printDoc = () => {
	const content = document.getElementById("doc-content")?.innerHTML;
	if (!content) {
		return;
	}

	// Open a new window or use an existing print-friendly page
	const printWindow = window.open("", "", "height=400,width=800");
	if (!printWindow) {
		return;
	}

	// Populate the new window with the div content
	printWindow.document.write("<html><body>");
	printWindow.document.write(content);
	printWindow.document.write("</body></html>");

	const els1 = printWindow.document.querySelectorAll(".toolbar");
	els1.forEach((el) => el.parentNode?.removeChild(el));

	const els2 = printWindow.document.querySelectorAll(".user-input");
	els2.forEach((el) => el.parentNode?.removeChild(el));

	const els3 = printWindow.document.querySelectorAll(".note");
	els3.forEach((el) => el.parentNode?.removeChild(el));

	printWindow.print();
};
