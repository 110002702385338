import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";
import { type DoneFn, SignIn } from "@/views/SignIn";
import { Globe2 } from "lucide-react";
import { type PropsWithChildren, useEffect, useState } from "react";
import { SWRConfig } from "swr";
import { useSearch } from "wouter";

const SigninDialog = ({
	onDone,
	onOpenChange,
	open,
	token,
}: {
	onDone: DoneFn;
	onOpenChange: (arg0: boolean) => void;
	open: boolean;
	token?: string;
}) => (
	<Dialog onOpenChange={onOpenChange} open={open}>
		<DialogContent
			className="sm:max-w-[424px] md:top-[40%] py-10 z-50"
			hideClose={true}
		>
			<DialogHeader>
				<DialogTitle className="flex items-center gap-1">
					<Globe2 />
					Grant Orb
				</DialogTitle>
			</DialogHeader>

			<SignIn onDone={onDone} token={token} />
		</DialogContent>
	</Dialog>
);

export const Protected = ({
	children,
}: PropsWithChildren<{ apiOnly?: boolean }>) => {
	const query = useSearch();

	const qs = new URLSearchParams(query);

	const [open, setOpen] = useState(false);
	const [token] = useState<string | undefined>(qs.get("token") ?? undefined);

	useEffect(() => {
		window.history.pushState({}, "", window.location.pathname);
		if (token) {
			setOpen(true);
		}
	}, [token]);

	const onDone = async (teamId?: string) => {
		if (teamId) {
			window.location.href = `/teams/${teamId}` + window.location.pathname;
		} else {
			window.history.pushState({}, "", window.location.pathname);
		}
		setOpen(false);
	};

	return (
		<SWRConfig
			value={{
				onError: (error) => {
					if (error.status === 401 || error.status === 403) {
						setOpen(true);
					}
				},
			}}
		>
			<SigninDialog
				onDone={onDone}
				onOpenChange={setOpen}
				open={open}
				token={token}
			/>
			{children}
		</SWRConfig>
	);
};
