import type { GetDocRes, ListDocsRes } from "@/types/docs";
import type { GetTemplateRes } from "@/types/templates";
import { format } from "date-fns";
import {
	CircleDollarSign,
	Clock,
	ExternalLink,
	MapPin,
	Tag,
} from "lucide-react";

interface TemplateTagsProps {
	doc: GetDocRes | GetTemplateRes | ListDocsRes[0];
	noLinks?: boolean;
	truncate?: boolean;
}

export const TemplateTags = ({ doc, noLinks, truncate }: TemplateTagsProps) => {
	return (
		<>
			{"template" in doc && doc?.template && "type" in doc?.template && (
				<div className="text-sm text-gray-800 bg-indigo-100 rounded-full px-3 font-medium">
					{doc.template?.type}
				</div>
			)}

			{!noLinks && doc.url && (
				<a
					className="flex items-center text-sm text-gray-800"
					href={doc.url}
					rel="noreferrer"
					target="_blank"
				>
					<ExternalLink className="mr-1" size="18" />
					Website
				</a>
			)}

			{doc.location && (
				<div className="flex items-center text-sm text-gray-800">
					<MapPin className="mr-1" size="18" />
					{doc.location}
				</div>
			)}

			{doc.schedule && (
				<div className="flex items-center text-sm text-gray-800">
					<Clock className="mr-1" size="18" />
					{doc.schedule?.anytime ? (
						"Year round"
					) : (
						<>
							{doc.schedule.startAt
								? format(doc.schedule.startAt, "d MMM")
								: "Unknown"}{" "}
							to{" "}
							{doc.schedule.endAt
								? format(doc.schedule.endAt, "d MMM")
								: "Unknown"}
						</>
					)}
				</div>
			)}

			{doc.amount && (
				<div className="flex items-center text-sm text-gray-800">
					<CircleDollarSign className="mr-1" size="18" />
					{doc.amount}
				</div>
			)}

			{doc.category && (
				<div className="flex items-center text-sm text-gray-800">
					<Tag className="mr-1" size="18" />
					{truncate && doc.category.length > 35
						? doc.category.substring(0, 35) + "..."
						: doc.category}
				</div>
			)}
		</>
	);
};
