import {
	NavigationMenu,
	NavigationMenuItem,
	NavigationMenuList,
	navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { postFetch } from "@/lib/fetchers.js";
import { cn } from "@/lib/utils.js";
import type { MeRes } from "@/types/auth.js";
import { ChevronsUpDown, Globe2, Lock, Sparkles } from "lucide-react";
import { useEffect, useState } from "react";
import { useSWRConfig } from "swr";
import useSWRMutation from "swr/mutation";
import { Link, useLocation } from "wouter";

import { ReferralButton } from "./ReferralButton.js";
import { SigninButton } from "./SigninButton.js";
import { TeamBanner } from "./TeamBanner.js";
import { UserMenu } from "./UserMenu.js";
import { useUser } from "./hooks/useUser.js";
import { Button } from "./ui/button.js";
import {
	HoverCard,
	HoverCardContent,
	HoverCardTrigger,
} from "./ui/hover-card.js";

export function Nav() {
	const { user } = useUser();
	const { mutate } = useSWRConfig();
	const { isMutating, trigger: logout } = useSWRMutation(
		"/a/auth/logout",
		postFetch,
	);

	const _logout = async () => {
		await logout();
		mutate("/a/me");
	};

	return (
		<>
			{user?.debugUser && (
				<div className="flex items-center justify-center bg-red-500 text-white text-2xl p-2 gap-4 z-50">
					You are in debug user mode!
					<Button disabled={isMutating} onClick={_logout} size="sm">
						Logout
					</Button>
				</div>
			)}

			<div
				className="sticky top-0 border-b bg-background w-full p-2"
				// style={{ zIndex: "999" }}
			>
				<div className="md:container flex items-center justify-between">
					<div className="flex items-center gap-2">
						<div className="block md:hidden">
							<NavMobile />
						</div>
						<div className="hidden md:block">
							<NavDesktop />
						</div>
						<FeatureBanner user={user} />
					</div>

					<div className="flex items-center gap-2">
						{user && (
							<ReferralButton
								className="text-lg hidden"
								size="sm"
								user={user}
							/>
						)}

						<div className="hidden md:block">{!user && <SigninButton />}</div>

						{user && <UserMenu user={user} />}
					</div>
				</div>
			</div>
		</>
	);
}

const menuTriggerStyle = cn(
	navigationMenuTriggerStyle(),
	"flex data-[active]:bg-inherit data-[active]:underline px-2 py-0 text-lg md:text-sm",
);

interface NavDesktopProps {
	orientation?: "horizontal" | "vertical";
}

const NavDesktop = ({ orientation = "horizontal" }: NavDesktopProps) => {
	const [loc] = useLocation();

	return (
		<NavigationMenu orientation={orientation}>
			<NavigationMenuList className="ml-4 flex flex-col md:flex-row items-start gap-4">
				<NavigationMenuItem>
					<Link href="/">
						<NavigationMenuLink
							active={loc === "/"}
							className={menuTriggerStyle}
						>
							Home
						</NavigationMenuLink>
					</Link>
				</NavigationMenuItem>
				<NavigationMenuItem>
					<Link href="/docs/new">
						<NavigationMenuLink
							active={loc === "/docs/new"}
							className={menuTriggerStyle}
						>
							Create
						</NavigationMenuLink>
					</Link>
				</NavigationMenuItem>
				<NavigationMenuItem>
					<Link href="/drafts">
						<NavigationMenuLink
							active={loc === "/drafts"}
							className={menuTriggerStyle}
						>
							Drafts
						</NavigationMenuLink>
					</Link>
				</NavigationMenuItem>
				<NavigationMenuItem>
					<Link href="/docs">
						<NavigationMenuLink
							active={loc === "/docs"}
							className={menuTriggerStyle}
						>
							Proposals
						</NavigationMenuLink>
					</Link>
				</NavigationMenuItem>
				<NavigationMenuItem>
					<Link href="/grants">
						<NavigationMenuLink
							active={loc === "/grants"}
							className={menuTriggerStyle}
						>
							Grants
						</NavigationMenuLink>
					</Link>
				</NavigationMenuItem>
			</NavigationMenuList>
		</NavigationMenu>
	);
};

const NavMobile = () => {
	const [open, setOpen] = useState(false);
	const [loc] = useLocation();

	useEffect(() => {
		setOpen(false);
	}, [loc]);

	return (
		<Sheet onOpenChange={setOpen} open={open}>
			<SheetTrigger asChild>
				<Button className="flex items-center gap-1" variant="outline">
					<Globe2 />
					<span>Grant Orb</span>
					<ChevronsUpDown size={18} />
				</Button>
			</SheetTrigger>
			<SheetContent
				className="h-[400px] bg-gray-100"
				side="bottom"
				style={{ zIndex: "999" }}
			>
				<NavDesktop orientation="vertical" />
			</SheetContent>
		</Sheet>
	);
};

const NavigationMenuLink = ({
	active,
	children,
	...props
}: {
	[key: string]: any;
	active: boolean;
	children: React.ReactNode;
}) => (
	<div {...(active ? { "data-active": true, ...props } : props)}>
		{children}
	</div>
);

const Logo = () => (
	<div className="flex items-center gap-4">
		<Link className="text-sm font-semibold flex items-center gap-1 " to="/">
			<Globe2 />
			<span>Grant Orb</span>
		</Link>
		<div className="text-sm border-l border-slate-800 pl-4 hidden md:block">
			Write grants in minutes
		</div>
	</div>
);

const FeatureBanner = ({}: { user?: MeRes }) => {
	const [open, setOpen] = useState(false); //user && !user.hasOrg
	const [location] = useLocation();

	useEffect(() => {
		setOpen(false);
	}, [location]);

	// useEffect(() => {
	//   setOpen(user && !user.hasOrg)
	// }, [user])

	return (
		<HoverCard closeDelay={1700} open={open} openDelay={100}>
			<HoverCardTrigger
				onClick={() => {
					setOpen((v) => !v);
				}}
			>
				<div className="relative text-black bg-gray-100 px-3 py-1 rounded-xl text-sm font-bold cursor-pointer">
					<div className="absolute -top-2 -left-2">
						<Sparkles className="fill-green-300 stroke-green-500" size={25} />
					</div>
					AI Search
				</div>
			</HoverCardTrigger>
			<HoverCardContent>
				<TeamBanner />
			</HoverCardContent>
		</HoverCard>
	);
};

export const DataSafeBanner = () => {
	return (
		<HoverCard closeDelay={500} openDelay={100}>
			<HoverCardTrigger>
				<div className="flex gap-2 items-center text-black bg-gray-100 px-3 py-1 rounded-xl text-sm font-bold cursor-pointer">
					<Lock size={15} />
					Data Protected
				</div>
			</HoverCardTrigger>
			<HoverCardContent>
				All your data is encrypted and stored securely in our highly secured
				cloud infrastructure. All your data belongs to you and we do not use it
				for training AI models.
			</HoverCardContent>
		</HoverCard>
	);
};
