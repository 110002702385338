import { AnimatedCheckmark } from "@/components/AnimatedCheckmark";
import { Button, type ButtonProps } from "@/components/ui/button.js";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";
import { sendBeacon } from "@/lib/utils";
import type { MeRes } from "@/types/auth";
import { DialogTrigger } from "@radix-ui/react-dialog";
import { Heart } from "lucide-react";
import { useState } from "react";

type ReferralDialogProps = {
	user?: MeRes;
} & ButtonProps;

export const ReferralButton = ({
	className,
	size,
	user,
	variant,
}: ReferralDialogProps) => {
	const [open, setOpen] = useState(false);

	const copyToClipboard = async () => {
		sendBeacon("Referral Link Copied");
		setOpen(true);
		const link = `https://grantorb.com/?ref=${user?.referralCode}`;
		await navigator.clipboard.writeText(link);
	};

	return (
		<Dialog onOpenChange={setOpen} open={open}>
			<DialogTrigger asChild>
				<Button
					className={className}
					disabled={!user}
					onClick={copyToClipboard}
					size={size}
					variant="ghost"
				>
					<Heart className="mr-2" size="15" />
					Share
				</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-[425px]">
				<DialogHeader>
					<DialogTitle>Referral Link Copied</DialogTitle>
					<DialogDescription className="text-base text-gray-800 pt-4">
						<p>
							Your link has been copied to clipboard. Paste it in an email or
							feel free to share it on social media.
						</p>
						<br />
						<p>
							Refer a friend or co-worker to Grant Orb and gift them upto 20%
							discount.
						</p>
					</DialogDescription>
				</DialogHeader>
				<div className="w-full p-4 border rounded">
					<AnimatedCheckmark size="xLarge" />
				</div>
				<DialogFooter>
					<Button
						onClick={() => {
							setOpen(false);
						}}
						variant="secondary"
					>
						Close
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};
