import { Button } from "@/components/ui/button";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { postFetch } from "@/lib/fetchers";
import type { MeRes } from "@/types/auth";
import type { ListTeamRes } from "@/types/teams";
import { ChevronsUpDown, Users } from "lucide-react";
import { useEffect, useState } from "react";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { Link, useLocation } from "wouter";

export const UserMenu = ({ user }: { user: MeRes }) => {
	const [open, setOpen] = useState(false);
	const [location] = useLocation();

	const { data: teams } = useSWR<ListTeamRes>(open ? "/a/teams" : null, {
		onError: () => {},
	});

	const { trigger: _logout } = useSWRMutation("/a/auth/logout", postFetch);

	const logout = async () => {
		await _logout();
		window.location.reload();
	};

	useEffect(() => {
		setOpen(false);
	}, [location, user]);

	return (
		<DropdownMenu onOpenChange={setOpen} open={open}>
			<DropdownMenuTrigger asChild>
				<Button className="flex items-center gap-1 px-1" variant="ghost">
					<div className="border-0 bg-indigo-500 hover:bg-indigo-600 hover:text-white text-white text-xs font-bold rounded-full w-8 h-8 flex flex-col place-items-center place-content-center">
						{user?.abbr}
					</div>

					<div className="text-sm font-medium truncate text-ellipsis max-w-10 md:max-w-60">
						{/* {user?.teamName
              ? <Users className="inline-block mr-2" size={20} />
              : <User className="inline-block mr-2" size={20} />
            } */}
						{user?.teamName ?? "Personal"}
					</div>
					<ChevronsUpDown className="ml-1" size={20} />
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent className="w-40">
				<TeamList teams={teams} />
				<DropdownMenuItem>
					<Link to="/team">Setup Team</Link>
				</DropdownMenuItem>
				<DropdownMenuSeparator />
				<DropdownMenuItem className="cursor-pointer" onClick={logout}>
					Logout
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	);
};

const TeamList = ({ teams }: { teams?: ListTeamRes }) => {
	if (!teams || teams.length === 0) {
		return null;
	}

	return (
		<DropdownMenuGroup>
			{teams.map((team) => (
				<DropdownMenuItem key={team.id}>
					<a
						className="cursor-pointer"
						href={team.personal ? "/" : `/teams/${team.id}`}
					>
						<Users className="inline-block mr-2" size={18} />
						{team.name}
					</a>
				</DropdownMenuItem>
			))}
			<DropdownMenuSeparator />
		</DropdownMenuGroup>
	);
};
