import { ListDocs } from "./ListDocs.js";
import { ListDrafts } from "./ListDrafts.js";

export const Docs = () => {
	return <ListDocs />;
};

export const Drafts = () => {
	return <ListDrafts />;
};
