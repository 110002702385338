import { PricingTable } from "@/components/PricingTable";
import { DocForm } from "@/components/doc/DocForm";
import { useUser } from "@/components/hooks/useUser";
import type { GetDocRes } from "@/types/docs";
import type { ListTeamRes } from "@/types/teams";
import type { GetTemplateRes } from "@/types/templates";
import { useState } from "react";
import useSWR from "swr";
import { useParams } from "wouter";

export const CreateDocFromTemplate = () => {
	const { id } = useParams<{ id: string }>();
	return <CreateDoc isGrant={true} templateId={id} />;
};

export const CreateDocFromDraft = () => {
	const { id } = useParams<{ id: string }>();
	return <CreateDoc draftId={id} />;
};

export const CreateDocFromGeneric = () => {
	return <CreateDoc />;
};

export const CreateDoc = ({
	draftId,
	isGrant,
	templateId: tid,
}: {
	draftId?: string;
	isGrant?: boolean;
	templateId?: string;
}) => {
	const { user } = useUser();
	const [templateId, setTemplateId] = useState(tid ?? "grant");

	const { data: teams } = useSWR<ListTeamRes>(isGrant ? "/a/teams" : null, {
		onError: () => {},
		refreshInterval: 900000,
		revalidateOnFocus: false,
	});

	const { data: baseTemplate, isLoading } = useSWR<GetTemplateRes>(
		`/p/templates/${templateId}`,
		{
			refreshInterval: 900000,
			revalidateOnFocus: false,
		},
	);

	const { data: draftFrom } = useSWR<GetDocRes>(
		draftId ? `/a/docs/${draftId}` : null,
		{
			revalidateOnFocus: false,
		},
	);

	if (!baseTemplate || isLoading) {
		return <div>Loading...</div>;
	}

	return (
		<>
			{(!isGrant || user) && <PricingTable closed={true} />}

			<DocForm
				baseTemplate={baseTemplate}
				defaultDraft={draftFrom?.draft}
				onTemplateChange={setTemplateId}
				parentId={tid}
				teams={teams}
			/>
		</>
	);
};
