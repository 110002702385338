import { Button } from "@/components/ui/button.js";
import { Card, CardContent } from "@/components/ui/card";
import { Suspense, lazy } from "react";
import { Link, useParams } from "wouter";

import { ListPublishedDocs } from "./ListDocs.js";

const CreateTemplateDialog = lazy(
	async () => await import("@/components/doc/CreateTemplateDialog.tsx"),
);

export const Grants = () => {
	const { categoryId } = useParams<{ categoryId: string }>();

	return (
		<>
			<Card>
				<CardContent className="flex flex-wrap items-center justify-start gap-3">
					<Suspense fallback={null}>
						<CreateTemplateDialog label="Create Template" />
					</Suspense>
					<Button asChild variant="link">
						<a href="#grants-you-can-apply-to">Available Grant</a>
					</Button>
					<Button asChild variant="link">
						<a href="#grants-you-missed">Missed Grants</a>
					</Button>
					<Button asChild variant="link">
						<a href="#your-bookmarks">Your bookmarks</a>
					</Button>
					<Button asChild variant="link">
						<a href="#grant-reports">Grant Reports</a>
					</Button>
				</CardContent>
			</Card>

			<ListPublishedDocs publicDocs={false} title="Your templates" />

			<ListPublishedDocs
				categoryId={categoryId}
				desc="Grant templates curated by the GrantOrb team"
				id="grants-you-can-apply-to"
				publicDocs={true}
				templateIds={["grant", "loi", "rfp"]}
				title="Grants you can apply to"
			/>

			<ListPublishedDocs
				bookmarked={true}
				id="your-bookmarks"
				publicDocs={false}
				title="Your bookmarks"
			/>

			<ListPublishedDocs
				categoryId={categoryId}
				desc="The deadline on these grants has passed"
				hideMore={true}
				id="grants-you-missed"
				lapsed={true}
				publicDocs={true}
				templateIds={["grant", "loi", "rfp"]}
				title="Grants you missed"
			/>

			<ListPublishedDocs
				categoryId={categoryId}
				desc="Great reports are key to securing future funding"
				id="grant-reports"
				publicDocs={true}
				templateIds={["grant-report"]}
				title="Grant reports"
			/>
		</>
	);
};
