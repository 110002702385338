import { SignInForm } from "@/views/SignIn.js";

import { useUser } from "./hooks/useUser.js";

export const SigninBanner = () => {
	const { isLoading, user } = useUser();

	if (isLoading) return null;
	if (user) return null;

	return (
		<div className="fixed bottom-2 left-0 w-full md:w-[400px] p-2">
			<div className="p-4 shadow-xl border border-stone-600 rounded-xl flex flex-col gap-3 bg-black text-white">
				<div>
					<div className="text-xl font-bold mb-1">
						Signup / Signin to get started
					</div>
					<p className="text-gray-400">
						We have an exciting roadmap of AI features to help you win your
						grants. Project ideas, grant deadline alerts, and more.
					</p>
				</div>

				<SignInForm buttonSize="sm" hideMessage={true} />
			</div>
		</div>
	);
};
