import { TextInput } from "@/components/TextInput.js";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form.js";
import { postFetch } from "@/lib/fetchers.js";
import type { GetDocRes } from "@/types/docs.js";
import type { UpdateSectionReq } from "@/types/sections.js";
import type { GetTemplateRes } from "@/types/templates.js";
import { useForm } from "react-hook-form";
import useSWR, { useSWRConfig } from "swr";
import useSWRMutation from "swr/mutation";

import { Button } from "./ui/button.js";

interface EditSectionProps {
	docId: string;
	onDone: () => void;
	section: GetDocRes["sections"][0];
}

export const EditSection = ({ docId, onDone, section }: EditSectionProps) => {
	const { mutate } = useSWRConfig();
	const { trigger: updateSection } = useSWRMutation(
		`/a/sections/${section.id}`,
		postFetch<UpdateSectionReq>,
	);

	const { data: docTemplate } = useSWR<GetTemplateRes>(`/p/templates/${docId}`);
	const template = docTemplate?.fields.find((v) => v.id === section.sectionId);

	const form = useForm({ values: { userValue: section.text ?? "" } });

	const onSubmit = form.handleSubmit(async (v) => {
		await updateSection(v);
		mutate(`/a/docs/${docId}`);
		onDone();
	});

	return (
		<Form {...form}>
			<form className="pb-6" onSubmit={onSubmit}>
				<FormField
					control={form.control}
					name="userValue"
					render={({ field }) => (
						<FormItem className="flex flex-col gap-3">
							<FormLabel>
								<div className="text-lg flex items-center gap-2">
									{template?.title}
								</div>
								<div className="text-stone-500 font-normal mt-1 ">
									{template?.description}
								</div>
							</FormLabel>
							<FormControl>
								<TextInput {...field} autoFocus={true} />
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
				<div className="mt-2">
					<Button className="mr-2" type="submit" variant="default">
						Save
					</Button>
					<Button onClick={onDone} variant="secondary">
						Cancel
					</Button>
				</div>
			</form>
		</Form>
	);
};
