import { Title } from "@/components/Title.js";
import type { ListDocsRes } from "@/types/docs.js";
import { InfoIcon } from "lucide-react";
import useSWR from "swr";

import { DocListItem, MoreListItem, PublishedDocListItem } from "./ListDocs.js";

interface ListDraftsProps {
	limit?: number;
}

export const ListDrafts = ({ limit }: ListDraftsProps) => {
	const limitCount = (limit ?? 100) + 1;
	const { data: draft } = useSWR<ListDocsRes>(`/a/drafts?limit=${limitCount}`, {
		onError: () => {},
	});

	if (!draft) {
		return null;
	}

	return (
		<div>
			<Title desc="Proposals still being worked on" text="Drafts" />

			{draft.length === 0 && (
				<div className="bg-white border border-dashed rounded-xl p-4 text-xl text-gray-600">
					<InfoIcon className="inline-block mr-2" />
					You have no proposal drafts saved.
				</div>
			)}

			<div className="grid gap-cols-1 md:grid-cols-3 gap-4">
				{draft
					.slice(0, limit)
					.map((draft) =>
						draft.name ? (
							<PublishedDocListItem doc={draft} key={draft.id} />
						) : (
							<DocListItem doc={draft} key={draft.id} pathPrefix="/drafts/" />
						),
					)}

				{draft.length === limitCount && (
					<MoreListItem label="Drafts" path="/drafts" />
				)}
			</div>
		</div>
	);
};
