import type { GetDocRes } from "@/types/docs";

import {
	DocActions,
	RevisionsButton,
	UpdateDocButton,
} from "@/components/doc/DocActions";
import { Section, UserInput } from "@/components/doc/Section";
import { ValidationReport } from "@/components/doc/ValidationReport";
import { useUser } from "@/components/hooks/useUser";
import { Card, CardContent } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { Quote } from "lucide-react";
import React from "react";
import useSWR from "swr";
import { useParams } from "wouter";

const apiPath = (id: string, versionId?: string) => {
	const path = versionId ? `/a/docs/${id}/${versionId}` : `/a/docs/${id}`;
	return path;
};

export const ShowDoc = () => {
	const { id, versionId } = useParams<{ id: string; versionId?: string }>();

	const { data: doc } = useSWR<GetDocRes>(apiPath(id, versionId));

	const [showInputs, setShowInputs] = React.useState(false);

	if (!doc) {
		return <div>Loading...</div>;
	}

	return (
		<>
			<Card>
				<CardContent className="text-amber-500 text-md flex items-center text-xl">
					<Quote className="hidden md:block mr-2" size="90" />
					The information, statistics, and data provided might not be accurate.
					It is essential that you review and verify the proposal below. Use
					"Edit" to fix any concerns
				</CardContent>
			</Card>

			<div className="flex flex-wrap items-center justify-between gap-2 md:px-4">
				<div className="flex flex-wrap items-center gap-2 w-full">
					<DocActions doc={doc} />

					<div className="flex items-center space-x-2 px-4">
						<Label htmlFor="show-inputs">Show Inputs</Label>
						<Switch
							checked={showInputs}
							id="show-inputs"
							onCheckedChange={setShowInputs}
						/>
					</div>

					<div className="hidden md:block ">
						<UpdateDocButton doc={doc} />
					</div>

					<div className="px-4">
						<RevisionsButton doc={doc} versionId={versionId} />
					</div>
				</div>

				{/* <DocUserInfo doc={doc} /> */}
			</div>

			<RenderDoc doc={doc} id={id} showInputs={showInputs} />
		</>
	);
};

interface RenderDocProps {
	doc: GetDocRes;
	id: string;
	showInputs: boolean;
}

export const RenderDoc = ({ doc, id, showInputs }: RenderDocProps) => {
	const { user } = useUser();

	return (
		<div
			className="bg-background shadow border border-stone-50 p-3 md:p-12 rounded-xl w-full"
			id="doc-content"
		>
			<div className="prose-lg prose-stone prose-h1:text-2xl md:prose-h1:text-4xl prose-h2:text-xl md:prose-h2:text-3xl">
				<h1>{doc.title}</h1>

				{showInputs && doc.userText && (
					<UserInput
						showTitle={true}
						text={doc.userText}
						title="Proposal short description"
					/>
				)}

				{doc?.sections
					.filter((s) => !s.hidden)
					.map((section, index) => (
						<Section
							disabled={!user}
							docId={id}
							key={index}
							showInputs={showInputs}
							value={section}
						/>
					))}
			</div>

			{doc.review && (
				<Card>
					<ValidationReport doc={doc} showCriteria={showInputs} />
				</Card>
			)}
		</div>
	);
};
