import type { GetDocRes } from "@/types/docs";

import { EditSection } from "@/components/EditSection.js";
import { Button } from "@/components/ui/button.js";
import { BarChart2, Copy, Info, Pencil } from "lucide-react";
import React from "react";

export const UserInput = ({
	format,
	showTitle,
	text,
	title,
}: {
	format?: boolean;
	showTitle: boolean;
	text: string;
	title: string;
}) => (
	<div className="user-input text-lg text-stone-500 p-3 rounded-md bg-stone-100">
		{showTitle && (
			<span className="flex items-center gap-1 block text-black font-semibold text-sm mb-2">
				<Info size="15" />
				{title}
			</span>
		)}
		{format ? <pre className="whitespace-pre-wrap">{text}</pre> : text}
	</div>
);

interface SectionProps {
	disabled?: boolean;
	docId: string;
	showInputs?: boolean;
	showTitle?: boolean;
	showToolbar?: boolean;
	value: GetDocRes["sections"][0];
}

export const Section = ({
	disabled = false,
	docId,
	showInputs,
	showTitle = true,
	showToolbar = true,
	value,
}: SectionProps) => {
	const [edit, setEdit] = React.useState(false);

	const copy = async () => {
		await navigator.clipboard.writeText(`${value.title}\n\n${value.text}`);
	};

	return (
		<div className="group/section w-full">
			<div className="flex flex-wrap items-center justify-between gap-2">
				{showTitle && <h2 className="!my-2">{value.title}</h2>}

				{showToolbar && (
					<div className="toolbar group-hover/section:flex flex-wrap hidden items-center gap-2 text-stone-800">
						<div className="flex items-center text-sm text-black p-2">
							<BarChart2 className="mr-1" size="15" />
							{value.text?.trim().split(/\s+/).length} words,{" "}
							{value.text?.length} characters
						</div>
						<Button
							disabled={disabled || edit}
							onClick={() => {
								setEdit((v) => !v);
							}}
							size="sm"
							variant="ghost"
						>
							<Pencil className="mr-1" size="15" />
							{value.edited ? "Edit (Edited)" : "Edit"}
						</Button>
						<Button onClick={copy} size="sm" variant="ghost">
							<Copy className="mr-1" size="15" />
							Copy to clipboard
						</Button>
					</div>
				)}
			</div>

			{showInputs && value.userText && (
				<UserInput
					showTitle={showTitle}
					text={value.userText}
					title="Section short description"
				/>
			)}

			{/* {value.note && (
        <div className="note text-sm text-stone-800 p-3 rounded-lg bg-amber-50">
          <AlertTriangle className="inline-block mr-1" size={20} />
          {value.note}
        </div>
      )} */}

			{edit ? (
				<EditSection
					docId={docId}
					onDone={() => {
						setEdit(false);
					}}
					section={value}
				/>
			) : (
				<div dangerouslySetInnerHTML={{ __html: value.html }}></div>
			)}
		</div>
	);
};
