import { Suspense, lazy } from "react";
import { SWRConfig } from "swr";
import { Redirect, Route, Router, Switch } from "wouter";

import { Layout } from "./components/Layout.tsx";
import { Protected } from "./components/Protected.tsx";
import { getFetch } from "./lib/fetchers.ts";
import { parsePath } from "./lib/utils.ts";
import {
	CreateDocFromGeneric,
	CreateDocFromTemplate,
} from "./views/CreateDoc.tsx";
import { Docs, Drafts } from "./views/Docs.tsx";
import { Grants } from "./views/Grants.tsx";
import { Home } from "./views/Home.tsx";
import { ShowDoc } from "./views/ShowDoc.tsx";

const EditDoc = lazy(async () => await import("./views/EditDoc.tsx"));
const EditDraft = lazy(async () => await import("./views/EditDraft.tsx"));
const EditTemplate = lazy(async () => await import("./views/EditTemplate.tsx"));
const ShowFeaturedDoc = lazy(
	async () => await import("./views/ShowFeaturedDoc.tsx"),
);
const Team = lazy(async () => await import("./views/Team.tsx"));
const JoinTeam = lazy(async () => await import("./views/JoinTeam.tsx"));

const { base } = parsePath(window.location.pathname);

export const App = () => {
	const routes = (
		<Switch>
			<Route component={Home} path="/" />
			<Route component={JoinTeam} path="/join/:inviteCode" />
			<Route component={Team} path="/team" />
			<Route component={CreateDocFromGeneric} path="/docs/new" />
			<Route component={Docs} path="/docs" />
			<Route component={ShowDoc} path="/docs/:id" />
			<Route component={EditDoc} path="/docs/edit/:id" />
			<Route component={ShowDoc} path="/docs/:id/:versionId" />
			<Route component={ShowFeaturedDoc} path="/featured/docs/:id" />
			<Route component={Drafts} path="/drafts" />
			<Route component={EditDraft} path="/drafts/:id" />
			<Route component={EditTemplate} path="/templates/:id" />
			<Route component={Grants} path="/grants" />
			{/* <Route component={GrantCategory} path="/categories/:categoryId" />
      <Route component={GrantCategories} path="/categories" /> */}

			<Route component={CreateDocFromTemplate} path="/grants/:id" />
			<Route>
				<Redirect to="/" />
			</Route>
		</Switch>
	);

	return (
		<SWRConfig value={{ fetcher: getFetch }}>
			<Router base={base}>
				<Protected>
					<Layout>
						<Suspense fallback={<div>Loading...</div>}>{routes}</Suspense>
					</Layout>
				</Protected>
			</Router>
		</SWRConfig>
	);
};
