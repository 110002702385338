import type { MeRes } from "@/types/auth.ts";
import useSWR from "swr";

export const useUser = (showAuth?: boolean) => {
	const { data, error, isLoading } = useSWR<MeRes>("/a/me", {
		errorRetryCount: 0,
		refreshInterval: 900000,
		revalidateOnFocus: false,
		...(showAuth === true ? {} : { onError: () => {} }),
	});

	return {
		isError: error !== undefined,
		isLoading,
		user: data,
	};
};
