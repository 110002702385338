import { parsePath } from "./utils.js";

const { teamId } = parsePath(window.location.pathname);

const _fetch = async <ARG = unknown, RES = unknown>(
	method: string,
	path: string,
	options?: { arg?: ARG; multiPart?: boolean; teamId?: string },
) => {
	const teamId = options?.teamId;
	const _path = "/api" + (teamId ? `/teams/${teamId}` + path : path);

	let headers = {};

	// if (!options?.arg) {
	//   throw new Error('arg is required')
	// }

	if (!options?.multiPart) {
		headers = { "Content-Type": "application/json" };
	}

	const body = options?.multiPart
		? (options.arg as unknown as FormData)
		: JSON.stringify(options?.arg);

	const res = await fetch(_path, {
		body,
		credentials: "include",
		headers,
		method,
	});

	if (!res.ok) {
		const error = new Error("Data fetching failed") as unknown as {
			info: string;
			status: number;
		};
		error.info = await res.text();
		error.status = res.status;
		throw error;
	}
	return await (res.json() as Promise<RES>);
};

export const getFetch = async <RES = unknown>(url: string) => {
	const _options = teamId ? { teamId } : undefined;
	return await _fetch<unknown, RES>("GET", url, _options);
};

export const postFetch = async <ARG = unknown, RES = unknown>(
	url: string,
	options: { arg: ARG },
) => {
	const _options = { ...options, ...(teamId ? { teamId } : {}) };
	return await _fetch<ARG, RES>("POST", url, _options);
};

export const postFetchMP = async <RES = unknown>(
	url: string,
	options: { arg: FormData },
) => {
	const _options = {
		multiPart: true,
		...options,
		...(teamId ? { teamId } : {}),
	};
	return await _fetch<FormData, RES>("POST", url, _options);
};
