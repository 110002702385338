import { Hint } from "@/components/Hint.js";
import { Button } from "@/components/ui/button";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import type { GetDocRes } from "@/types/docs";
import { Copy, Menu, Pencil, Printer, SquareUserRound } from "lucide-react";
import { Link } from "wouter";

import { copyDoc, printDoc } from "./util.js";

export const DocActions = ({ doc }: { doc: GetDocRes }) => {
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="ghost">
					<Menu className="mr-2" size="15" />
					Actions
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="start" className="w-56">
				<DropdownMenuLabel>Actions</DropdownMenuLabel>
				<DropdownMenuSeparator />
				<DropdownMenuGroup>
					<UpdateDocButton doc={doc} />
				</DropdownMenuGroup>

				<DropdownMenuSeparator />

				<DropdownMenuGroup className="w-full">
					{/* <DropdownMenuItem onClick={() => { copyDoc(doc, true) }}>
            <Copy className="mr-2" size="15" />Copy Markdown
          </DropdownMenuItem> */}

					<DropdownMenuItem
						onClick={() => {
							copyDoc(doc);
						}}
					>
						<Copy className="mr-2" size="15" />
						Copy Text
					</DropdownMenuItem>

					<DropdownMenuSeparator />

					<DropdownMenuItem
						onClick={() => {
							printDoc();
						}}
					>
						<Printer className="mr-2" size="15" />
						Save as PDF
					</DropdownMenuItem>

					<DropdownMenuSeparator />

					{/* <Link to={`/docs/new?draftFrom=${doc.id}`}>
            <DropdownMenuItem>
              <Layers2 className="mr-2" size="15" />
              Generate similiar
            </DropdownMenuItem>
          </Link> */}
				</DropdownMenuGroup>
			</DropdownMenuContent>
		</DropdownMenu>
	);
};

export const UpdateDocButton = ({ doc }: { doc: GetDocRes }) => {
	return (
		<Button
			asChild
			className="bg-indigo-500 hover:bg-indigo-600 text-white"
			size="sm"
		>
			<Link to={`/docs/edit/${doc.id}`}>
				<Pencil className="mr-2" size="15" />
				Update with AI
			</Link>
		</Button>
	);
};

export const RevisionsButton = ({
	doc,
	versionId,
}: {
	doc: GetDocRes;
	versionId?: string;
}) => {
	const currentVersionId = versionId ?? doc?.defaultVersion;

	if (!doc?.versions || doc.versions.length === 1) {
		return null;
	}

	return (
		<div className="flex items-center gap-2 text-sm font-semibold">
			Revisions
			{doc?.versions?.map((vid, index) =>
				vid === currentVersionId ? (
					<div className="px-2 rounded-md bg-indigo-400 text-white" key={vid}>
						{index + 1}
					</div>
				) : (
					<Link
						className="bg-stone-100 hover:bg-stone-200 rounded-md px-2"
						key={vid}
						to={`/docs/${doc.id}/${vid}`}
					>
						{index + 1}
					</Link>
				),
			)}
		</div>
	);
};

export const DocUserInfo = ({ doc }: { doc: GetDocRes }) => {
	if (!doc?.user) {
		return null;
	}
	return (
		<div className="flex items-center gap-2 p-4 text-sm">
			<SquareUserRound size={20} />
			{doc.user.name}
		</div>
	);
};
