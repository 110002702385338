import type { CreateDocReq } from "@/types/docs";
import type { GetTemplateRes } from "@/types/templates";
import type { Resolver } from "react-hook-form";

export type TemplateUpdates = Record<
	string,
	{
		title?: string;
	}
>;

export type SaveHook = (docId: string) => Promise<void>;

export interface DocFormType {
	eligibilityCriteria?: string;
	assessmentCriteria?: string;
	criteria?: string;
	draft: Record<string, DocDraft[0]>;
	name?: string;
	useEnglish?: boolean;
}

export type DocDraft = Array<{
	id: string;
	userText: string;
}>;

export const valuesToDraft = (
	values: DocFormType["draft"],
): CreateDocReq["draft"] => {
	return Object.keys(values)
		.map((key) => ({
			id: key,
			userText: values[key].userText ?? "",
		}))
		.filter((item) => item.userText && item.userText?.length > 0);
};

export const draftToValues = (
	draft?: DocDraft,
	template?: GetTemplateRes["fields"],
): DocFormType["draft"] => {
	if (!template) {
		return {};
	}

	return template.reduce<DocFormType["draft"]>((acc, item) => {
		const d = draft?.find((v) => v.id === item.id);
		acc[item.id] = d ?? { id: item.id, userText: item.value ?? "" };
		return acc;
	}, {});
};

export const valuesResolver =
	(template: GetTemplateRes["fields"]): Resolver<DocFormType, any> =>
	async (data: DocFormType) => {
		const errors: Record<string, { message: string }> = {};

		Object.keys(data.draft).forEach((k) => {
			const d = data.draft[k];
			const t = template.find((t) => t.id === k);
			const id = `draft.${k}.userText`;

			if ((!d.userText || d.userText === "") && t?.required) {
				errors[id] = { message: "This is required" };
				return;
			}

			if (!d.userText) {
				return;
			}

			const words = d.userText.match(/\b\w+\b/g)?.length ?? 0;
			if (d.userText.length > 0 && words < 5) {
				errors[id] = { message: "Too short minimum 5 words" };
				return;
			}

			if (d.userText.length > (t?.maxLength ?? 4000)) {
				errors[id] = {
					message: `Too long maximum ${t?.maxLength ?? 4000} characters`,
				};
			}
		});

		return Object.keys(errors).length === 0
			? { errors: {}, values: data }
			: { errors, values: {} };
	};
