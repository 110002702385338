import React from "react";

interface MarkdownRendererProps {
	markdown: string;
}

export const Markdown: React.FC<MarkdownRendererProps> = ({ markdown }) => {
	const parseMarkdown = (text: string): string => {
		let html = text;

		// Convert headers (h1 to h6)
		html = html.replace(/^#{1,6}\s(.+)$/gm, (match) => {
			const level = match.indexOf(" ");
			const content = match.slice(level + 1);
			return `<h${level}>${content}</h${level}>`;
		});

		// Convert bold
		html = html.replace(/\*\*(.+?)\*\*/g, "<strong>$1</strong>");
		html = html.replace(/__(.+?)__/g, "<strong>$1</strong>");

		// Convert italic
		html = html.replace(/\*(.+?)\*/g, "<em>$1</em>");
		html = html.replace(/_(.+?)_/g, "<em>$1</em>");

		// Convert links
		html = html.replace(
			/\[(.+?)\]\((.+?)\)/g,
			'<a href="$2" class="text-blue-600 hover:underline">$1</a>',
		);

		// Convert unordered lists
		html = html.replace(/^\s*[-*+]\s+(.+)$/gm, '<li class="for-ul">$1</li>');
		html = html.replace(
			/(<li class=\"for-ul\">.*<\/li>)/s,
			'<ul class="list-outside list-disc">$1</ul>',
		);

		// Convert ordered lists
		html = html.replace(/^\s*\d+\.\s+(.+)$/gm, '<li class="for-ol">$1</li>');
		html = html.replace(
			/(<li class=\"for-ol\">.*<\/li>)/s,
			'<ol class="list-outside list-decimal">$1</ol>',
		);

		// Convert code blocks
		html = html.replace(
			/`(.+?)`/g,
			'<code class="bg-gray-100 px-1 rounded">$1</code>',
		);

		// Convert paragraphs
		html = html.replace(/^(?!<[hula])(.+)$/gm, "<p>$1</p>");

		return html;
	};

	return (
		<div
			className="prose"
			dangerouslySetInnerHTML={{ __html: parseMarkdown(markdown) }}
		/>
	);
};
