import { AlertNote } from "@/components/AlertNote.js";
import { AnimatedCheckmark } from "@/components/AnimatedCheckmark.js";
import { ExtendedButton } from "@/components/ExtendedButton.jsx";
import { HelpButton } from "@/components/HelpButton.js";
import { Hint } from "@/components/Hint.js";
import { DataSafeBanner } from "@/components/Nav.js";
import { PricingTable } from "@/components/PricingTable.js";
import { TaskProgressDialog } from "@/components/TaskProgressDialog";
import { DocFormItem, DocFormItemAuto } from "@/components/doc/DocFormItem";
import { useUser } from "@/components/hooks/useUser.js";
import { Button } from "@/components/ui/button.js";
import { Card, CardContent } from "@/components/ui/card.js";
import { Checkbox } from "@/components/ui/checkbox.js";
import { Form } from "@/components/ui/form.tsx";
import { Label } from "@/components/ui/label.js";
import { postFetch } from "@/lib/fetchers";
import { cn, debounce } from "@/lib/utils";
import type {
	CreateDocReq,
	CreateDocRes,
	CreateDocSuccessRes,
	GetDocRes,
} from "@/types/docs";
import type { ListTeamRes } from "@/types/teams.js";
import type { GetTemplateRes } from "@/types/templates";
import { CircleHelp, ClipboardCheck, Wand2 } from "lucide-react";
import type React from "react";
import { Suspense, lazy, useEffect, useMemo, useRef, useState } from "react";
import {
	type FieldErrors,
	type WatchObserver,
	useForm,
	useWatch,
} from "react-hook-form";
import useSWRMutation from "swr/mutation";
import { Link, useLocation } from "wouter";

import { DocFormHeader } from "./DocFormHeader.js";
import { DocProgress } from "./DocProgress.js";
import { TemplateTags } from "./TemplateTags.js";
import {
	type DocFormType,
	type SaveHook,
	draftToValues,
	valuesResolver,
	valuesToDraft,
} from "./doc.js";
import { Markdown } from "../Markdown.js";

const DraftWizard = lazy(
	async () => await import("@/components/doc/DraftWizard.tsx"),
);

const ValidateWizard = lazy(
	async () => await import("@/components/doc/ValidateWizard.tsx"),
);

const CreateTemplateDialog = lazy(
	async () => await import("@/components/doc/CreateTemplateDialog.tsx"),
);

const AddSectionDialog = lazy(
	async () => await import("@/components/doc/AddSectionDialog.tsx"),
);

interface DocFormProps {
	SectionExtra?:
		| React.ComponentType<{
				section: GetDocRes["sections"][0];
		  }>
		| undefined;
	baseTemplate?: GetTemplateRes;
	defaultDraft?: CreateDocReq["draft"];
	disabled?: boolean;
	doc?: GetDocRes;
	isEditing?: boolean;
	onTemplateChange?: (template: string) => void;
	parentId?: string;
	teams?: ListTeamRes;
}

export const DocForm = ({
	SectionExtra,
	baseTemplate,
	defaultDraft,
	doc,
	isEditing,
	onTemplateChange,
	parentId,
	teams,
}: DocFormProps) => {
	const { user } = useUser();
	const [showWizard, setShowWizard] = useState(doc ? false : true);
	const [generate, setGenerate] = useState(false);
	const [draftVersion, setDraftVersion] = useState(0);
	const [, navigate] = useLocation();

	const generateRef = useRef(generate);

	const {
		data,
		isMutating,
		trigger: createDoc,
	} = useSWRMutation("/a/docs", postFetch<CreateDocReq, CreateDocRes>);

	const creditCount = user?.credits.proposals ?? 0;
	const isRevision = doc?.isRevision ?? false;

	const template = doc?.template ?? baseTemplate?.template;
	const fields = doc?.fields ?? baseTemplate?.fields ?? [];
	const visibleFields = fields.filter((t) => !t.hidden);
	const formFields = visibleFields?.filter((t) => !t.auto);

	const showCustomizeButton = baseTemplate?.canCustomize;
	const name = doc?.name ?? baseTemplate?.name;
	const description = doc?.description ?? baseTemplate?.description;
	const eligibilityCriteria = baseTemplate?.eligibilityCriteria;

	const form = useForm<DocFormType>({
		defaultValues: {
			useEnglish: true,
		},
		mode: "onChange",
		resolver: valuesResolver(visibleFields),
	});

	const useEnglish = useWatch({ control: form.control, name: "useEnglish" });

	const isConfirmSubmitEnabled =
		form.formState.isValid && creditCount > 0 && !isMutating;

	const isSubmitEnabled = generate && isConfirmSubmitEnabled;

	const progress = useMemo(() => {
		if (isRevision) {
			return 100;
		} else if (isSubmitEnabled) {
			return 75;
		} else if (doc?.review) {
			return 50;
		} else if (form.formState.isValid) {
			return 25;
		}

		const totalFields = Object.keys(form.getValues("draft") ?? {}).length;
		const inCompleteFields = Object.keys(form.formState.errors).length;
		const completeFields = totalFields - inCompleteFields;

		if (totalFields === 0) {
			return 0;
		}

		const percentageComplete = (completeFields / totalFields) * 100;
		const percentageChange = (percentageComplete / 100) * 25;

		return percentageChange;
	}, [doc, form.formState]);

	useEffect(() => {
		generateRef.current = generate;
	}, [generate]);

	useEffect(() => {
		const useEnglish = doc?.useEnglish ?? true;
		const draft = draftToValues(doc?.draft ?? defaultDraft, formFields);
		const eligibilityCriteria =
			doc?.eligibilityCriteria ?? baseTemplate?.eligibilityCriteria;
		const assessmentCriteria =
			doc?.assessmentCriteria ?? baseTemplate?.assessmentCriteria;

		form.reset({ eligibilityCriteria, assessmentCriteria, draft, useEnglish });
	}, [doc, baseTemplate, defaultDraft]);

	const submit = async (values: DocFormType) => {
		return await createDoc({
			draft: valuesToDraft(values.draft),
			generate,
			id: doc?.id,
			useEnglish: values.useEnglish,
			...(values.assessmentCriteria
				? { assessmentCriteria: values.assessmentCriteria }
				: {}),
			...(values.eligibilityCriteria
				? { eligibilityCriteria: values.eligibilityCriteria }
				: {}),
			...(values.name ? { name: values.name } : {}),
			...(parentId ? { parentId } : {}),
			...(template ? { templateId: template.id } : {}),
		});
	};

	const _saveDraft = async (values: DocFormType, fn?: SaveHook) => {
		const res = (await submit(values)) as CreateDocSuccessRes;
		if (fn) {
			await fn(res.id);
		}
		if (!doc) {
			navigate(`/drafts/${res.id}`, { replace: true });
		}

		setDraftVersion((v) => v + 1);
		return res;
	};

	const saveDraft = async (values: DocFormType) => {
		if (!generateRef.current) _saveDraft(values);
	};

	useEffect(() => {
		const fn: WatchObserver<DocFormType> = async (values, { type }) => {
			if (type !== "change") return;

			if (form.formState.isDirty) {
				await saveDraft(values as DocFormType);
			}
		};

		const subscription = form.watch(debounce(fn, 3000));
		return () => {
			subscription.unsubscribe();
		};
	}, [form.watch, doc]);

	useEffect(() => {
		if (!form.formState.isDirty) {
			form.trigger();
		}
	}, [form.formState.isDirty]);

	return (
		<>
			{!isEditing && (
				<div className="sticky top-0 bg-background p-4 py-3">
					<DocProgress progress={progress} />
				</div>
			)}

			<DocFormHeader
				name={name}
				parentId={parentId}
				teams={teams}
				template={baseTemplate}
			/>

			{description && (
				<p className="text-xl text-stone-500 px-4">{description}</p>
			)}

			{eligibilityCriteria && (
				<p className="text-xl text-stone-500 bg-gray-100 py-4 px-2 rounded-xl">
					<div className="text-sm font-semibold pl-3">Elegibility Criteria</div>
					<Markdown markdown={eligibilityCriteria} />
				</p>
			)}

			<Suspense fallback={null}>
				{showWizard && (
					<DraftWizard
						disabled={isSubmitEnabled || isMutating}
						docId={doc?.id}
						examples={template?.examples}
						hideExamples={user?.hasCreatedDoc}
						onTemplateChange={onTemplateChange}
						parentId={parentId}
						placeholder={template?.placeholderText}
						saveDraft={async (fn: SaveHook) => {
							await _saveDraft(form.getValues(), fn);
						}}
						templateId={template?.id}
						value={doc?.userText}
					/>
				)}
			</Suspense>

			<Card>
				<CardContent className="flex flex-wrap items-center justify-start gap-3">
					{showCustomizeButton &&
						((parentId ?? doc?.id) ? (
							<Button asChild className="border-black" variant="outline">
								<Link to={`/templates/${parentId ?? doc?.id}`}>
									<ClipboardCheck className="mr-2" />
									Customize Form
								</Link>
							</Button>
						) : (
							<Suspense fallback={null}>
								<CreateTemplateDialog
									disabled={isRevision || isSubmitEnabled || isMutating}
									label="Customize Form"
									saveDraft={async (name: string) => {
										const res = (await submit({
											...form.getValues(),
											name,
										})) as CreateDocSuccessRes;
										navigate(`/templates/${res.id}`, { replace: true });
									}}
								/>
							</Suspense>
						))}

					<Button
						className="border-black"
						onClick={() => setShowWizard(!showWizard)}
						variant="outline"
					>
						{showWizard ? "Hide Wizard" : "Show Wizard"}
					</Button>

					{!showCustomizeButton && (
						<Suspense fallback={null}>
							<AddSectionDialog
								disabled={isRevision || isSubmitEnabled || isMutating}
								docId={doc?.id}
								fields={visibleFields}
								saveDraft={async (fn: SaveHook) => {
									await _saveDraft(form.getValues(), fn);
								}}
							/>
						</Suspense>
					)}

					<HelpButton
						link="https://grantorb.com/guides/how-to-write-a-winning-grant-in-5-mins-with-grant-orbs-ai/"
						linkText="How To Write a Winning Grant in 5 Mins With Grant Orb's AI
          "
						text={`Use the wizard or enter specifics about your project in the form below. If needed attach related documents like old proposals, etc. When ready click "Create ${
							template?.name
						}" at the bottom, AI will create a full ${template?.name?.toLocaleLowerCase()}.`}
					/>

					{draftVersion > 0 && (
						<div
							className="flex items-center gap-2 text-sm text-stone-800 p-2 px-4 bg-gray-100/80 rounded-full"
							key={draftVersion}
						>
							<AnimatedCheckmark size="20" />
							Draft Saved
						</div>
					)}

					<DataSafeBanner />

					{doc && <TemplateTags doc={doc as GetTemplateRes} noLinks={true} />}
				</CardContent>
			</Card>

			{data && "taskId" in data && (
				<TaskProgressDialog
					description="Please wait a few minutes while we create your proposal"
					fields={visibleFields}
					redirectTo={`/docs/${data?.id}`}
					taskId={data?.taskId}
					title="Writing Proposal"
				/>
			)}

			<Form {...form}>
				<div className="flex flex-col gap-2">
					{visibleFields?.map((item, i) => {
						const section = doc?.sections.find((s) => s.sectionId === item.id);

						return (
							<div
								className="p-4 py-8 rounded-xl bg-background"
								key={`${item.id}-${i}`}
							>
								{item.auto ? (
									<DocFormItemAuto item={item} />
								) : (
									<DocFormItem
										control={form.control}
										disabled={!user}
										item={item}
									></DocFormItem>
								)}
								{SectionExtra && section && (
									<div className="border-t mt-2 pt-2">
										<SectionExtra section={section} />
									</div>
								)}
							</div>
						);
					})}
				</div>

				<Suspense fallback={null}>
					{template && (
						<ValidateWizard
							control={form.control}
							description={template?.assessment.description}
							disabled={
								!isSubmitEnabled &&
								user &&
								(!user.credits.proposals || user.credits.proposals === 0)
							}
							doc={doc}
							title={template?.assessment.title}
						/>
					)}
				</Suspense>

				{!isRevision && user?.credits.proposals === 0 && <PricingTable />}
			</Form>
			<Card>
				<CardContent className="space-y-4">
					{!form.formState.isValid && (
						<DocFormAlert
							errors={form.formState.errors}
							templateFields={fields}
						/>
					)}

					<div className="flex items-center gap-2">
						<Checkbox
							checked={useEnglish}
							className="h-8 w-8 border-stone-800"
							id="use-english"
							onCheckedChange={(v: boolean) => {
								form.setValue("useEnglish", v);
							}}
						/>
						<Label
							className="flex items-center gap-2 text-md font-medium leading-none"
							htmlFor="use-english"
						>
							Generate in English{" "}
							<Hint text="When checked the document will be generated in english. When unchecked the language of the outline will be used.">
								<CircleHelp />
							</Hint>
						</Label>
					</div>

					<div
						className={cn(
							"flex items-center gap-2",
							!isConfirmSubmitEnabled ? "opacity-50" : "",
						)}
					>
						<Checkbox
							className="h-8 w-8 border-stone-800"
							disabled={!isConfirmSubmitEnabled}
							id="ready"
							onCheckedChange={(v: boolean) => {
								setGenerate(v);
							}}
							required={true}
						/>
						<Label
							className="text-md font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
							htmlFor="ready"
						>
							I'm ready to generate the {template?.name}
						</Label>
					</div>

					<ExtendedButton
						disabled={!isSubmitEnabled}
						icon={Wand2}
						iconSize={22}
						isLoading={isMutating}
						label={`Create ${template?.name}`}
						loadingLabel="AI at work"
						onClick={form.handleSubmit(submit)}
					/>
				</CardContent>
			</Card>
		</>
	);
};

const DocFormAlert = ({
	errors,
	templateFields,
}: {
	errors: FieldErrors<DocFormType>;
	templateFields: GetTemplateRes["fields"];
}) => {
	const messages = Object.entries(errors)
		.map(([k, v]) => {
			const key = k.substring(k.indexOf(".") + 1, k.lastIndexOf("."));
			const item = templateFields?.find((f) => f.id === key);
			return `${item?.title} (${v.message})`;
		})
		.join(", ");

	return (
		<AlertNote
			message={messages}
			title="Please complete the required sections in the form above"
			variant="destructive"
		/>
	);
};
