import { postFetch } from "@/lib/fetchers.js";
import useSWRMutation from "swr/mutation";

import { Button } from "./ui/button.js";

export const SigninButton = () => {
	const { trigger } = useSWRMutation("/a/me", postFetch);
	return (
		<Button
			className="bg-indigo-500 text-lg"
			onClick={async () => {
				trigger();
			}}
			size="lg"
		>
			Signup / Signin
		</Button>
	);
};
