import { ProgressBar } from "@/components/ProgressBar.js";

interface DocProgressArgs {
	progress: number;
}

export const DocProgress = ({ progress }: DocProgressArgs) => {
	return (
		<ProgressBar
			markers={[
				{ label: "Start", position: 0 },
				{ label: "Outline Created", position: 25 },
				{ label: "Optional Assessment", position: 50 },
				{ label: "Ready to Create", position: 75 },
				{ label: "Done", position: 100 },
			]}
			progress={progress}
		/>
	);
};
